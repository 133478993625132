<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlHbjlSearch " :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"  :dynamic='true' :noOperation="true"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>
    

    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>

    <!-- 编辑弹窗 -->
    <general-form :FormDialog="editDialog" :FormList="FormList" :title="editTitle" :FormData="addform"  @cancelDialog="cancelEdit" @confirmDialog="confirmEdit"></general-form>

    

  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybjzqgl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          queryKey:'UserNumber',
          queryType:'0',
          queryValue:'',
          beginTime:'',
          endTime:'',
          dateScope:'',
        },
        orgs: [], // 用户可访问到的组织列表
        tableHeaderList:this.$store.state.headerList,
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{
          belongArea:'',
          installAddress:'',
          jzqName:'',
          jzqAddress:'',
          communication:'false',
          parsing:'1',
        },
        FormList:{//表格列
          column:[
            {columnKey:'belongArea',columnDescription:'所属区域',columnType:'text',prop:'belongArea',width:'120',placeholder:"所属区域",},
            {columnKey:'installAddress',columnDescription:'安装地址',columnType:'text',prop:'installAddress',width:'120',placeholder:"安装地址",},
            {columnKey:'jzqName',columnDescription:'集中器名称',columnType:'text',prop:'jzqName',width:'120',placeholder:"集中器名称",},
            {columnKey:'jzqAddress',columnDescription:'集中器地址',columnType:'text',prop:'jzqAddress',width:'120',placeholder:"集中器地址",},
            {
              columnKey:'communication',
              columnDescription:'通讯方式',
              columnType:'select',
              placeholder:"请选择通讯方式",
              prop:'communication',
              width:'120',
              seleceOption:[
                {label:'非NB-IOT',value:'false'},
                {label:'NB-IOT',value:'true'},
              ],
              typeId:'Hyb_CommunicateType',//从全局数据字典里获取下拉数据
            },
            {
              columnKey:'parsing',
              columnDescription:'解析方式',
              columnType:'select',
              placeholder:"请选择解析方式",
              prop:'parsing',
              width:'120',
              seleceOption:[
                {label:'version 1.0(5000端口)',value:'1'},
                {label:'version 2.0(6000端口)',value:'2'},
              ],
              typeId:'Hyb_AnalysisType',//从全局数据字典里获取下拉数据
            },
          ],
          //校验
          rule:{
            belongArea: [{ required: true, message: "所属区域不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        editDialog:false,//编辑弹窗
        editTitle:'修改',//编辑弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
      }
    },
    watch: {
      
    },
    computed: {
      // orgsTree() {
      //   return this.$store.state.AllArea;
      // },
    },
    filters: {
      
    },
    created() {
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })
      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'btnEdit'://编辑
            if(this.tableChooseRow.id){
              this.addform ={
                belongArea:'test1',
                installAddress:'test1',
                jzqName:'test1',
                jzqAddress:'test1',
                communication:'false',
                parsing:'1',
              }
              this.editDialog = true
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        this.loading = true
        this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
        this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        basicDataApi.hbjlGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        this.$store.state.tableDataSearch.push(this.addform)
        this.addform={
          name:'',
          type:'plbz',
          uploadFilsName:'',//上传文件名
        }
        this.FormDialog = false
      },
      // 编辑弹窗取消
      cancelEdit(val){
        this.editDialog = val
      },
      // 编辑弹窗提交
      confirmEdit(){
        this.$store.state.tableDataSearch.push(this.addform)
        this.addform={
          name:'',
          type:'plbz',
          uploadFilsName:'',//上传文件名
        }
        this.editDialog = false
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
